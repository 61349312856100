import React, { useEffect, useState } from "react";
import { Button, Image, Dropdown, Form, Modal } from "react-bootstrap";

const InactivityModal = (props) => {

  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="contact-delete-modal"
        backdrop="static"
      >
        <Modal.Body>
          <Form className="efi-bank-default-form">
            <div className="open-account-modal-heading">
              <Image
                className="delete-logo-avater"
                src={window.location.origin + "/img/feature/deleted.png"}
                type="image/png"
              />
              <div className="open-account-modal-info text-center">
                <h3>Warning</h3>
                <p>
                  You have been inactive for a while.
                  Do you wish to continue?
                </p>
              </div>
            </div>
            <div className="form-modal-footer">
              {/* <Button className="cancel-button" onClick={props.onHide}>
                Cancel
              </Button> */}
              <Button
                className="cancel-button"
                // disabled={contactsDelete.buttonDisable}
                onClick={() => props.onHide()}
              >
                {/* {contactsDelete.buttonDisable ? <ButtonLoader/> : "Delete Recipient"} */}
                Confirm
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default InactivityModal;
