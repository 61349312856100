import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logoutStart, profileStart } from "../../store/slices/AdminSlice";
import EmptyHeader from "./Header/EmptyHeader"
import PageLoader from "../Helper/PageLoader";
import { Navigate } from "react-router-dom";
import SidebarIndex from "./Sidebar/SidebarIndex";
import MainHeader from "./Header/MainHeader";
import CollapseSidebar from "./Sidebar/CollapseSidebar";
import Sidebar from "./Sidebar/Sidebar";
import InactivityModal from "./InactivityModal";
import configuration from "react-global-configuration";
import useLogout from "../hooks/useLogout";
import { getErrorNotificationMessage } from "../Helper/NotificationMessage";

const AuthLayout = ({ children }) => {
  return(
    <div style={{position:"relative"}}>
    <EmptyHeader/>
    {children}
    </div>
  )
};

const EmptyLayout = ({ children }) => <>{children}</>;

const AdminLayout = ({ children }) => {

  const dispatch = useDispatch();
  const sidebar = useSelector((state) => state.common.sidebar);
  const profile = useSelector((state) => state.admin.profile);
  const logOut = useSelector(state => state.admin.logout);
  const [inactivityModal, setInactivityModal] = useState(false);
  const [skipRender, setSkipRender] = useState(true);
  const [isIdle, setIsIdle] = useState(false);
  const idleTimerRef = useRef(null);
  const {logout} = useLogout();
  const idleTimeout = configuration.get("configData.inactivity_in_seconds") * 1000 || 1800000;
  // const idleTimeout = 10000;
  const idleWarningTime = idleTimeout * 0.8; 
  const idleRemainingTime = idleTimeout * 0.2;
  const [inactiveLogout, setInactiveLogout] = useState(false);
  
  const handleIdle = () => {
    setIsIdle(true);
    setInactivityModal(true);
  };

  const handleFullIdle = () => {
    if (isIdle) {
      dispatch(logoutStart());
      setInactiveLogout(true);
    }
  };

  const resetIdleTimer = () => {
    setIsIdle(false);

    if (idleTimerRef.current) {
      clearTimeout(idleTimerRef.current);
    }
    
    idleTimerRef.current = setTimeout(handleIdle, idleWarningTime);
  };

  useEffect(() => {
    if (inactivityModal) {
      const remainingTimeout = setTimeout(handleFullIdle, idleRemainingTime);
      return () => clearTimeout(remainingTimeout);
    }
  }, [inactivityModal]);

  useEffect(() => {
    const activityEvents = ['mousemove', 'keydown', 'scroll', 'click'];
    activityEvents.forEach(event => window.addEventListener(event, resetIdleTimer));

    resetIdleTimer();

    return () => {
      if (idleTimerRef.current) {
        clearTimeout(idleTimerRef.current);
      }
      activityEvents.forEach(event => window.removeEventListener(event, resetIdleTimer));
    };
  }, []);

  useEffect(() => {
    if (!profile.buttonDisable && Object.keys(profile.data).length == 0) {
      dispatch(profileStart());
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(()=> {
    if(!skipRender && !logOut.loading && Object.keys(logOut.data).length > 0){
      logout();
      inactiveLogout && getErrorNotificationMessage("Inactivity Limit Exceeded! You have been logged out due to security reasons.");
    }
    setSkipRender(false);
  }, [logOut])


  return (
    <>
      {profile.loading ? <PageLoader/> : Object.keys(profile.data).length > 0 ? (
        <div className={`admin-ui-sec ${profile.data.role == 2 ? 'agent-panel' : 'user-panel'}`}>
          {sidebar ? <CollapseSidebar /> : <Sidebar />}
          <div className={`admin-ui-main-wrapper ${sidebar ? "admin-collapse-main-wrapper" : ""}`}>
            <MainHeader />
            {children}
          </div>
        </div>
      ) : <Navigate to={"/login"}/>}

      {inactivityModal ? 
      <InactivityModal
        show={inactivityModal}
        onHide={() => {
          setInactivityModal(false);
          resetIdleTimer();  // Reset timer when the modal is closed
        }}
      /> : null}
    </>
  );
};


const OnboardingLayout = ({ children }) => {

  const dispatch = useDispatch();
  const sidebar = useSelector((state) => state.common.sidebar);
  const profile = useSelector((state) => state.admin.profile);

  useEffect(() => {
    if (!profile.buttonDisable && Object.keys(profile.data).length == 0) {
      dispatch(profileStart());
    }
  }, []);

  return (
    <>
      {profile.loading ? <PageLoader/> : Object.keys(profile.data).length > 0 ? <div className={`${!profile.loading && Object.keys(profile.data).length > 0 && profile.data.role == 2 ? 'agent-panel' : 'user-panel'}`}>
        <div>
          {children}
        </div>
      </div> : <Navigate to={"/login"}/>}
    </>
  );
};

const EmptyAdminLayout = ({ children }) => {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.admin.profile);
  useEffect(() => {
    dispatch(profileStart());
  }, []);
  return (
    <>
      {!profile.loading && Object.keys(profile.data).length > 0 ? (
        <div className="main-wrapper">
          {children}
        </div>
      ) : null}
    </>
  );
};

export { AuthLayout, EmptyLayout, AdminLayout, EmptyAdminLayout, OnboardingLayout };
