import React from "react";
import { Image } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const NoDataFoundNew = () => {
  return (
    <div className="no-data-found-sec">
     <LazyLoadImage
                    effect="blur"
        className="create-account-avater"
        src={window.location.origin + "/img/nodata-found-img.svg"}
      />
      <h5>No Data Found</h5>
    </div>
  );
};

export default NoDataFoundNew;
