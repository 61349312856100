import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { singleQuoteViewStart } from "../../store/slices/BeneficiariesSlice.jsx";
import Skeleton from "react-loading-skeleton";
import NoDataFoundNew from "../Helper/NoDataFoundNew.jsx";
import { useTranslation } from "react-multi-lang";

const QuoteView = () => {
  const location = useLocation();
  const t = useTranslation("quote.quote_list");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const singleQuoteView = useSelector(
    (state) => state.beneficiaries.singleQuoteView
  );
  useEffect(() => {
    dispatch(singleQuoteViewStart({ quote_id: location.state }));
  }, []);

  return (
    <>
      <div className="full-body-card-wrapped">
        {singleQuoteView.loading ? (
          <div className="quote-wrapped">
            <div className="quote-frame-head">
              <div className="quote-frame-titles">
                <Skeleton width={170} height={30} borderRadius={5} />
              </div>
              <div className="quote-frame-status">
                <Skeleton width={140} height={40} borderRadius={5} />

                <div className="status-type">
                  <Skeleton width={140} height={20} borderRadius={5} />
                </div>
              </div>
            </div>
            <div className="quote-space"></div>
            <div className="quote-frmae-info">
              <Row>
                <Col lg={4}>
                  <Skeleton height={500} borderRadius={5} />
                </Col>
                <Col lg={8}>
                  <Row>
                    <Col lg={6}>
                      <Skeleton height={250} borderRadius={5} />
                    </Col>
                    <Col lg={6}>
                      <Skeleton height={250} borderRadius={5} />
                    </Col>
                    <Col lg={12} className="mt-3">
                      <Skeleton height={230} borderRadius={5} />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </div>
        ) : (
          <div className="quote-wrapped">
            <div className="quote-frame-head">
              <div className="quote-frame-titles">
                <Link to={-1}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="26"
                    height="26"
                    data-name="Layer 2"
                    viewBox="0 0 24 24"
                  >
                    <path d="M22 11H4.414l5.293-5.293a1 1 0 10-1.414-1.414l-7 7a1 1 0 000 1.414l7 7a1 1 0 001.414-1.414L4.414 13H22a1 1 0 000-2z"></path>
                  </svg>
                </Link>
                <h2>Quote Details</h2>
              </div>
              <div className="quote-frame-status">
                <div
                  className={`status-badges  ${
                    singleQuoteView.data.quote.status == 0 ? "fail" : "success"
                  }`}
                >
                  <span>
                    {singleQuoteView.data.quote.status == 1 ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <g fill="#20bf55" data-name="Flat Color">
                          <path d="M12 22.75a10.75 10.75 0 010-21.5 10.53 10.53 0 014.82 1.15.75.75 0 01-.68 1.34 9 9 0 00-4.14-1A9.25 9.25 0 1021.25 12a2 2 0 000-.25.75.75 0 111.5-.14V12A10.76 10.76 0 0112 22.75z"></path>
                          <path d="M11.82 15.41a.7.7 0 01-.52-.22l-4.83-4.74a.75.75 0 010-1.06.77.77 0 011.07 0l4.29 4.23 9.65-9.49a.77.77 0 011.07 0 .75.75 0 010 1.06l-10.18 10a.74.74 0 01-.55.22z"></path>
                        </g>
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        enableBackground="new 0 0 512 512"
                        viewBox="0 0 512 512"
                      >
                        <path
                          fill="#ff4a4a"
                          d="M357.14 181.14l-73.355 73.36 73.356 73.36c7.812 7.808 7.812 20.472 0 28.28-3.903 3.907-9.024 5.86-14.141 5.86s-10.238-1.953-14.14-5.86l-73.36-73.355-73.36 73.356C178.239 360.047 173.118 362 168 362s-10.238-1.953-14.14-5.86c-7.813-7.808-7.813-20.472 0-28.28l73.355-73.36-73.356-73.36c-7.812-7.808-7.812-20.472 0-28.28 7.809-7.813 20.473-7.813 28.282 0l73.359 73.355 73.36-73.356c7.808-7.812 20.472-7.812 28.28 0 7.813 7.809 7.813 20.473 0 28.282zm79.88-106.16C388.668 26.63 324.379 0 256 0S123.332 26.629 74.98 74.98C26.63 123.332 0 187.621 0 256s26.629 132.668 74.98 181.02C123.332 485.37 187.621 512 256 512c46.813 0 92.617-12.758 132.46-36.895 9.45-5.722 12.47-18.02 6.747-27.464-5.723-9.45-18.023-12.47-27.469-6.746C334.145 461.242 295.504 472 256 472c-119.102 0-216-96.898-216-216S136.898 40 256 40s216 96.898 216 216c0 42.59-12.664 84.043-36.625 119.887-6.14 9.18-3.672 21.601 5.512 27.742 9.18 6.137 21.601 3.672 27.742-5.512C497 355.676 512 306.531 512 256c0-68.379-26.629-132.668-74.98-181.02zm0 0"
                          data-original="#000000"
                        ></path>
                      </svg>
                    )}
                  </span>
                  {singleQuoteView.data.quote.status_formatted}
                </div>
                <div className="status-type">
                  <p>
                    Type | <span>{singleQuoteView.data.quote.quote_type}</span>
                  </p>
                </div>
              </div>
            </div>

            <div className="quote-space"></div>
            {Object.keys(singleQuoteView.data).length > 0 ? (
              <div className="quote-frmae-info">
                <Row>
                  <Col lg={4}>
                    <div className="quote-info-card">
                      <div className="quote-card">
                        <h5>Charged Amount</h5>
                        <p>
                          {
                            singleQuoteView.data.quote.quote.charged_amount
                              .currency
                          }
                          :
                          <span>
                            {
                              singleQuoteView.data.quote.quote.charged_amount
                                .amount
                            }
                          </span>
                        </p>
                      </div>
                      {/* <div className="card-part"></div> */}
                      <div className="quote-card">
                        <h5>Converted Amount</h5>
                        <p>
                          {
                            singleQuoteView.data.quote.quote.converted_amount
                              .currency
                          }
                          :
                          <span>
                            {
                              singleQuoteView.data.quote.quote.converted_amount
                                .amount
                            }
                          </span>
                        </p>
                      </div>
                      {/* <div className="card-part"></div> */}
                      <div className="quote-card">
                        <h5>Recipient Amount</h5>
                        <p>
                          {" "}
                          {
                            singleQuoteView.data.quote.quote.recipient_amount
                              .currency
                          }{" "}
                          :{" "}
                          <span>
                            {
                              singleQuoteView.data.quote.quote.recipient_amount
                                .amount
                            }
                          </span>{" "}
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col lg={8}>
                    <Row>
                      <Col lg={6}>
                        <div className="quote-overlay-frame">
                          <div className="quote-overlay-title">
                            <h3> Fees </h3>
                          </div>
                          <div className="quote-space"></div>
                          <div className="quote-overlay-card">
                            <div className="quote-overlay-info">
                              <h5>Total Amount</h5>
                              <p>
                                {
                                  singleQuoteView.data.quote.quote.fees.total
                                    .currency
                                }
                                :
                                <span>
                                  {
                                    singleQuoteView.data.quote.quote.fees.total
                                      .amount
                                  }
                                </span>
                              </p>
                            </div>
                            <div className="quote-overlay-info">
                              <h5>Fixed Amount</h5>
                              <p>
                                {
                                  singleQuoteView.data.quote.quote.fees
                                    .fixed_amount.currency
                                }
                                :
                                <span>
                                  {
                                    singleQuoteView.data.quote.quote.fees
                                      .fixed_amount.amount
                                  }
                                </span>
                              </p>
                            </div>
                            <div className="quote-overlay-info">
                              <h5>Percentage Amount</h5>
                              <p>
                                {
                                  singleQuoteView.data.quote.quote.fees
                                    .percentage_amount.currency
                                }
                                :
                                <span>
                                  {
                                    singleQuoteView.data.quote.quote.fees
                                      .percentage_amount.amount
                                  }
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="quote-overlay-frame">
                          <div className="quote-overlay-title">
                            <h3> Forex Rate </h3>
                          </div>
                          <div className="quote-space"></div>
                          <div className="quote-overlay-card">
                            <div className="quote-overlay-info">
                              <h5>Total Amount</h5>
                              <p>
                                {singleQuoteView.data.quote.quote.fx_rate.value}
                              </p>
                            </div>
                            <div className="quote-overlay-info">
                              <h5>Plateform Rate</h5>
                              <p>
                                {
                                  singleQuoteView.data.quote.quote.fx_rate
                                    .details.platform_rate
                                }
                              </p>
                            </div>
                            <div className="quote-overlay-info">
                              <h5>Net Markup</h5>
                              <p>
                                {
                                  singleQuoteView.data.quote.quote.fx_rate
                                    .details.net_markup
                                }
                              </p>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col lg={12}>
                        <div className="quote-overlay-frame mt-4">
                          <div className="quote-overlay-title">
                            <h3>Receiving Details </h3>
                          </div>
                          <div className="quote-space"></div>
                          <div className="quote-overlay-card">
                            <div className="quote-overlay-info">
                              <h5> Recipient Amount</h5>
                              <p>
                                {
                                  singleQuoteView.data.quote.quote
                                    .recipient_amount.currency
                                }
                                :
                                <span>
                                  {
                                    singleQuoteView.data.quote.quote
                                      .recipient_amount.amount
                                  }
                                </span>
                              </p>
                            </div>
                            <div className="quote-overlay-info">
                              <h5>Delivery time</h5>
                              <p>
                                {singleQuoteView.data.quote.quote.delivery_time}
                              </p>
                            </div>
                            <div className="quote-overlay-info">
                              <h5>Expiry Time</h5>
                              <p>{singleQuoteView.data.quote.quote.expiry}</p>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            ) : (
              <NoDataFoundNew />
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default QuoteView;
