import React from "react";
import { Image } from "react-bootstrap";
import { useTranslation } from "react-multi-lang";
import { Link } from "react-router-dom";

const Page404 = () => {
  const t = useTranslation("helper.page_404");
  return (
    <div className="efi-bank-create-account-wrapped">
      <div className="helper-pages">
        <div className="no-bot-exists-img-sec">
          <Image
            className="no-bot-exists-img"
            src={window.location.origin + "/img/404.svg"}
          />
        </div>
        <div className="no-bot-exists-info">
          <h4>Page Not Found!</h4>
        </div>
        <div className="efi-auth-signin">
          <Link to="/" className="efi-primary-btn w-100">
            Go Home
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Page404;
