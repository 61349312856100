import React, { useState, useEffect } from "react";
import { Image, Row, Col, Form, Button } from "react-bootstrap";
import SingleTransactionFields from "./SingleTransactionFields";
import TransactionSuccess from "./TransactionSuccess";
import SingleBeneficiary from "./SingleBeneficiary";

const SingleTransactionIndex = (props) => {

  const [step, setStep] = useState(1);
  const [transferData, setTransferData] = useState(null);

  return (
    <div className="full-body-card-wrapped">
      <div className="single-transaction-body">
        <Row className="justify-content-center">
          <Col lg={5}>
            <div className="single-transaction-status">
              <ul className="list-unstyled">
                <li>
                  <div className={`transaction-status-card ${step >= 1 ? "active" : ""}`}>
                    <div className="status-count">1</div>
                    <div className="status-info">Select Amount</div>
                    <div className="status-check">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        enableBackground="new 0 0 512 512"
                        viewBox="0 0 100 100"
                      >
                        <path
                          fill="#f0f0f0"
                          fillRule="evenodd"
                          d="M95.338 2.5c-1.432 0-4.068.274-7.915.811-3.847.54-6.334 1.061-7.455 1.555-1.125.494-3.158 2.208-6.093 5.123-2.938 2.925-7.2 8.324-12.773 16.192-5.58 7.876-10.786 16.19-15.625 24.95a352.779 352.779 0 00-13.552 27.29c-3.879-6.642-7.384-11.145-10.533-13.513-3.141-2.378-5.791-3.564-7.946-3.564-1.682 0-3.79.897-6.333 2.683-2.54 1.792-3.81 3.545-3.81 5.274 0 1.252 1.165 3.107 3.496 5.56 4.616 4.877 8.702 10.049 12.239 15.524 2.16 3.274 3.596 5.284 4.312 6.016.712.726 2.882 1.099 6.51 1.099 5.049 0 8.047-.647 9-1.95.946-1.253 2.695-4.99 5.246-11.22 6.303-15.598 14.14-30.493 23.506-44.675 9.375-14.177 17.962-24.901 25.779-32.166 1.557-1.38 2.493-2.275 2.822-2.688.32-.415.484-.832.484-1.266 0-.686-.455-1.035-1.359-1.035z"
                          clipRule="evenodd"
                          data-original="#09b285"
                        ></path>
                      </svg>
                    </div>
                  </div>
                </li>
                <li>
                  <div className={`transaction-status-card ${step >= 2 ? "active" : ""}`}>
                    <div className="status-count">2</div>
                    <div className="status-info">Select Beneficiary</div>
                    <div className="status-check">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        enableBackground="new 0 0 512 512"
                        viewBox="0 0 100 100"
                      >
                        <path
                          fill="#f0f0f0"
                          fillRule="evenodd"
                          d="M95.338 2.5c-1.432 0-4.068.274-7.915.811-3.847.54-6.334 1.061-7.455 1.555-1.125.494-3.158 2.208-6.093 5.123-2.938 2.925-7.2 8.324-12.773 16.192-5.58 7.876-10.786 16.19-15.625 24.95a352.779 352.779 0 00-13.552 27.29c-3.879-6.642-7.384-11.145-10.533-13.513-3.141-2.378-5.791-3.564-7.946-3.564-1.682 0-3.79.897-6.333 2.683-2.54 1.792-3.81 3.545-3.81 5.274 0 1.252 1.165 3.107 3.496 5.56 4.616 4.877 8.702 10.049 12.239 15.524 2.16 3.274 3.596 5.284 4.312 6.016.712.726 2.882 1.099 6.51 1.099 5.049 0 8.047-.647 9-1.95.946-1.253 2.695-4.99 5.246-11.22 6.303-15.598 14.14-30.493 23.506-44.675 9.375-14.177 17.962-24.901 25.779-32.166 1.557-1.38 2.493-2.275 2.822-2.688.32-.415.484-.832.484-1.266 0-.686-.455-1.035-1.359-1.035z"
                          clipRule="evenodd"
                          data-original="#09b285"
                        ></path>
                      </svg>
                    </div>
                  </div>
                </li>
                <li>
                  <div className={`transaction-status-card ${step == 3 ? "active" : ""}`}>
                    <div className="status-count">3</div>
                    <div className="status-info">Review</div>
                    <div className="status-check">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        enableBackground="new 0 0 512 512"
                        viewBox="0 0 100 100"
                      >
                        <path
                          fill="#f0f0f0"
                          fillRule="evenodd"
                          d="M95.338 2.5c-1.432 0-4.068.274-7.915.811-3.847.54-6.334 1.061-7.455 1.555-1.125.494-3.158 2.208-6.093 5.123-2.938 2.925-7.2 8.324-12.773 16.192-5.58 7.876-10.786 16.19-15.625 24.95a352.779 352.779 0 00-13.552 27.29c-3.879-6.642-7.384-11.145-10.533-13.513-3.141-2.378-5.791-3.564-7.946-3.564-1.682 0-3.79.897-6.333 2.683-2.54 1.792-3.81 3.545-3.81 5.274 0 1.252 1.165 3.107 3.496 5.56 4.616 4.877 8.702 10.049 12.239 15.524 2.16 3.274 3.596 5.284 4.312 6.016.712.726 2.882 1.099 6.51 1.099 5.049 0 8.047-.647 9-1.95.946-1.253 2.695-4.99 5.246-11.22 6.303-15.598 14.14-30.493 23.506-44.675 9.375-14.177 17.962-24.901 25.779-32.166 1.557-1.38 2.493-2.275 2.822-2.688.32-.415.484-.832.484-1.266 0-.686-.455-1.035-1.359-1.035z"
                          clipRule="evenodd"
                          data-original="#09b285"
                        ></path>
                      </svg>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </Col>
          <Col lg={7}>
            {step == 1 ? (
              <SingleTransactionFields setStep={setStep} step={step} transferData={transferData} setTransferData={setTransferData} />
            ) : step == 2 ? (
              <SingleBeneficiary setStep={setStep} step={step} transferData={transferData} setTransferData={setTransferData} />
            ) : step == 3 ? (
              <TransactionSuccess setStep={setStep} step={step} transferData={transferData} />
            ) : null}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default SingleTransactionIndex;
