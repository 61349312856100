import React, { useEffect } from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { virtualAccountStart } from "../../store/slices/AccountsSlice";
import NoDataFound from "../Helper/NoDataFound";
import Skeleton from "react-loading-skeleton";
import { useTranslation } from "react-multi-lang";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { Link } from "react-router-dom";

const VirtualAccount = (props) => {
  const t = useTranslation("virtual_account");
  const dispatch = useDispatch();
  const virtualAccount = useSelector((state) => state.accounts.virtualAccount);

  useEffect(() => {
    dispatch(virtualAccountStart());
  }, []);

  return (
    <>
      <div className="full-body-card-wrapped">
        <div className="contact-list-card-area">
          <div className="payment-table-header">
            <h3> {t("account_details")}</h3>
          </div>
          {virtualAccount.loading ? (
            <>
              <Row>
                <Col md={6}>
                  <Skeleton height={300} width={"100%"} />
                </Col>
                <Col md={6}>
                  <Skeleton height={300} width={"100%"} />
                </Col>
              </Row>
            </>
          ) : Object.keys(virtualAccount.data).length > 0 ? (
            virtualAccount.data.is_virtual_account_exists ? (
              <React.Fragment>
                <div className="list-view-card-row">
                  <div className="list-view-card">
                    <div className="list-view-card-info">
                      <p>{t("account_id")}</p>
                      <h4>{virtualAccount.data.virtual_account.account_id}</h4>
                    </div>
                    <div className="list-view-card-info">
                      <p>{t("account_holder")}</p>
                      <h4>
                        {
                          virtualAccount.data.virtual_account
                            .account_holder_name
                        }
                      </h4>
                    </div>
                    <div className="list-view-card-info">
                      <p>{t("account_bank_address")}</p>
                      <h4>
                        {
                          virtualAccount.data.virtual_account
                            .account_bank_address
                        }
                      </h4>
                    </div>
                    <div className="list-view-card-info">
                      <p>{t("account_status")}</p>
                      <h4>{virtualAccount.data.virtual_account.status}</h4>
                    </div>
                    <div className="list-view-card-info">
                      <p>{t("created_at")}</p>
                      <h4>
                        {
                          virtualAccount.data.virtual_account
                            .created_at_formatted
                        }
                      </h4>
                    </div>
                  </div>
                  <div className="list-view-card">
                    <div className="list-view-card-info">
                      <p>{t("account_number")}</p>
                      <h4>
                        {virtualAccount.data.virtual_account.account_number}
                      </h4>
                    </div>
                    <div className="list-view-card-info">
                      <p>{t("bank_name")}</p>
                      <h4>
                        {virtualAccount.data.virtual_account.account_bank_name}
                      </h4>
                    </div>
                    <div className="list-view-card-info">
                      <p>{t("bank_country")}</p>
                      <h4>
                        {
                          virtualAccount.data.virtual_account
                            .account_bank_country
                        }
                      </h4>
                    </div>
                    <div className="list-view-card-info">
                      <p>{t("bank_code")}</p>
                      <h4>{virtualAccount.data.virtual_account.bank_code}</h4>
                    </div>
                    <div className="list-view-card-info">
                      <p>{t("updated_at")}</p>
                      <h4>
                        {
                          virtualAccount.data.virtual_account
                            .updated_at_formatted
                        }
                      </h4>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <div className="onboard-card">
                <LazyLoadImage
                  effect="blur"
                  className="onboarding-handle-img"
                src={window.location.origin + "/img/onboarding-handle.svg"}
              />
              <div className="onboard-card-info">
                <h5>{virtualAccount.data.note}</h5>
                <p>
                Welcome to EFIcyent. We are pleased to have you on board. EFIcyent provides an optimized platform for efficient product management, enabling you to seamlessly activate, deactivate, and manage product offerings. Our solution is designed to address the unique needs of your business, ensuring a streamlined and effective user experience.</p>
                </div>
                <Link to="/user-onboarding" className="efi-overlay-btn">
                Continue
                  </Link>
                </div>
              </React.Fragment>
            )
          ) : <NoDataFound/>}
        </div>
      </div>
    </>
  );
};
export default VirtualAccount;
