import React, { useEffect, useRef, useState } from "react";
import {
  Image,
  Button,
  Nav,
  Row,
  Col,
  Tab,
  Form,
  InputGroup,
  Table,
} from "react-bootstrap";
import "../Setting/SettingStyle.css";
import Select, { components } from "react-select";
import { Checkbox, Radio, Switch } from "pretty-checkbox-react";
import "@djthoms/pretty-checkbox";
import ProgressBar from "@ramonak/react-progress-bar";
import TwoSteoAuthModal from "../Modals/TwoStepAuthModal";
import ChangePasswordModal from "../Modals/ChangePasswordModal";
import { Link } from "react-router-dom";
import DeleteAccount from "../Modals/DeleteAccount";
import LogOutAllDevicesModal from "../Modals/LogOutAllDevicesModal";
import CopyToClipboard from "react-copy-to-clipboard";
import OtpInput from "react-otp-input";
import { useDispatch, useSelector } from "react-redux";
import {
  changePasswordStart,
  tfaEnableFailure,
  tfaEnableStart,
  tfaEnableSuccess,
  tfaStatusUpdateStart,
  updateAddressStart,
  updateServiceTypeStart,
} from "../../store/slices/AdminSlice";
import { ButtonLoader } from "../Helper/Loader";
import Skeleton from "react-loading-skeleton";
import {
  getErrorNotificationMessage,
  getSuccessNotificationMessage,
} from "../Helper/NotificationMessage";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-multi-lang";
import useLogout from "../hooks/useLogout";
import BillingAddress from "../Onboarding/OnboardingForm/BillingAddress";
import { countries } from "countries-list";
import isEqual from "lodash/isEqual";
import BusinessInformation from "./BusinessInformation";
import {
  businessDocumentsStatusStart,
  personalDocumentsStatusStart,
} from "../../store/slices/BusinessSlice";
import NoDataFound from "../Helper/NoDataFound";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import IndividualAccountDetails from "./IndividualAccountDetails";
import BusinessAccountDetails from "./BusinessAccountDetails";

const SettingIndex = (props) => {
  const { logout } = useLogout();
  const t = useTranslation("settings.setting");
  const dispatch = useDispatch();
  const [authmodalShow, authModalShow] = useState(false);
  const [passwordChangeModal, setPasswordChangeModal] = useState(false);
  const [deleteaccountModal, setDeleteaccountModal] = useState(false);
  const [logoutdevices, setLogoutdevices] = useState(false);
  const [showPassword, setShowPassword] = useState({
    old_password: true,
    password: true,
    confirmPassword: true,
  });
  const [tfaShowPassword, setTfaShowPassword] = useState(true);
  const [otp, setOtp] = useState("");
  const [passField, setPassField] = useState(false);
  const formRef = useRef();
  const [state, setState] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);
  const profile = useSelector((state) => state.admin.profile);
  const tfaEnable = useSelector((state) => state.admin.tfaEnable);
  const tfaStatusUpdate = useSelector((state) => state.admin.tfaStatusUpdate);
  const changePassword = useSelector((state) => state.admin.changePassword);
  const updateAddress = useSelector((state) => state.admin.updateAddress);
  const updateServiceType = useSelector(
    (state) => state.admin.updateServiceType
  );
  const [skipRender, setSkipRender] = useState(true);
  const [deliverySameAsBilling, setDeliverySameAsBilling] = useState(false);
  const businessDocumentsStatus = useSelector(
    (state) => state.business.businessDocumentsStatus
  );
  const personalDocumentsStatus = useSelector((state) => state.business.personalDocumentsStatus);

  // const [activeKey, setActiveKey] = useState(profile.data.onboarding > 2 ? profile.data.user_type == 1 ? "address" : "business_info" : "password"); 
  const [activeKey, setActiveKey] = useState(profile.data.onboarding >= 3 ? "onboarding" : "password"); 

  const customStyles = {
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      left: "0px",
      borderRadius: "8px",
      overflow: "hidden",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      minWidth: 250,
      fontSize: "0.85em",
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "3px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      },
    }),
    container: (provided) => ({ ...provided, width: "auto" }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "transparent!important",
      border: "1px solid #E7E7E7!important",
      borderRadius: "8px!important",
      boxShadow: "none!important",
      height: "45px",
      display: "flex",
      alignItems: "center",
      alignItemsContent: "center",
      cursor: "pointer",
      fontSize: "0.9em",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#757575",
      fontSize: "0.9em",
      fontWeight: "400",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#000",
      display: "flex",
      alignItems: "center",
      gap: "0.5em",
      fontSize: "0.9em",
      fontWeight: "400",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      color: "#0194ff!important",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: "#111",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused || state.isSelected ? '#0194ff' : '#fff',
      color: state.isFocused || state.isSelected ? '#fff' : '#000',
      ":hover": {
              backgroundColor: "#0194ff",
              color: "#fff"
            },
  }),
  };

  const countryOptions = Object.keys(countries)
    .filter(
      (country) =>
        ![
          "AF",
          "BY",
          "CF",
          "CN",
          "CU",
          "CD",
          "ET",
          "IN",
          "IR",
          "IQ",
          "LY",
          "ML",
          "NI",
          "KP",
          "SO",
          "SS",
          "SD",
          "SY",
          "US",
          "VE",
          "YE",
          "ZW",
        ].includes(country)
    )
    .map((alpha2) => ({
      value: alpha2,
      label: countries[alpha2].name,
    }));

  const tfaSchema = Yup.object().shape({
    otp: Yup.string().required(t("required")),
    password: Yup.string()
      .required(t("password.required"))
      .matches(/^\S*$/, t("password.no_spaces"))
      // .test(
      //   "complexity",
      //   "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character",
      //   (value) => {
      //     if (!value) return false; // Skip validation if value is not provided
      //     const hasUppercase = /[A-Z]/.test(value);
      //     const hasLowercase = /[a-z]/.test(value);
      //     const hasNumber = /[0-9]/.test(value);
      //     const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(value);
      //     return hasUppercase && hasLowercase && hasNumber && hasSpecialChar;
      //   }
      // )
      .min(6, t("password.min_length_invalid")),
  });

  const changePasswordSchema = Yup.object().shape({
    old_password: Yup.string()
      .required(t("old_password.required"))
      .matches(/^\S*$/, t("old_password.no_spaces"))
      .test(
        "complexity",
        "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character",
        (value) => {
          if (!value) return false; // Skip validation if value is not provided
          const hasUppercase = /[A-Z]/.test(value);
          const hasLowercase = /[a-z]/.test(value);
          const hasNumber = /[0-9]/.test(value);
          const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(value);
          return hasUppercase && hasLowercase && hasNumber && hasSpecialChar;
        }
      )
      .min(6, t("old_password.min_length_invalid"))
      .max(24, t("old_password.max_length_invalid")),
    password: Yup.string()
      .required(t("new_password.required"))
      .matches(/^\S*$/, t("new_password.no_spaces"))
      .test(
        "complexity",
        "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character",
        (value) => {
          if (!value) return false; // Skip validation if value is not provided
          const hasUppercase = /[A-Z]/.test(value);
          const hasLowercase = /[a-z]/.test(value);
          const hasNumber = /[0-9]/.test(value);
          const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(value);
          return hasUppercase && hasLowercase && hasNumber && hasSpecialChar;
        }
      )
      .min(6, t("new_password.min_length_invalid"))
      .max(24, t("new_password.max_length_invalid")),
    password_confirmation: Yup.string()
      .required(t("confirm_password.required"))
      .matches(/^\S*$/, t("confirm_password.no_spaces"))
      .min(6, t("old_password.min_length_invalid"))
      .when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          t("confirm_password.invalid")
        ),
      }),
  });

  const validationSchema = Yup.object().shape({
    billing_primary_address: Yup.string()
      .required(t("required"))
      .matches(/^\S.*$/, t("billing_address_1.invalid"))
      .min(5, t("required_note"))
      .trim(),
    billing_secondary_address: Yup.string()
      .required(t("required"))
      .matches(/^\S.*$/, t("billing_address_2.invalid"))
      .min(5, t("required_note"))
      .trim(),
    billing_city: Yup.string()
      .required(t("required"))
      .matches(/^\S.*$/, t("invalid", { value: "Billing city" }))
      .min(3, t("min_length_invalid"))
      .trim(),
    billing_state: Yup.string()
      .required(t("required"))
      .matches(/^\S.*$/, t("invalid", { value: "Billing state" }))
      .min(2, t("state_length_invalid"))
      .trim(),
    billing_zipcode: Yup.string()
      .required(t("required"))
      .matches(/^\S.*$/, t("invalid", { value: "Billing pincode" }))
      .min(4, t("zipcode_length_invalid"))
      .trim(),
    billing_country: Yup.string()
      .required(t("required"))
      .matches(/^\S.*$/, t("invalid", { value: "Billing country" }))
      .trim(),
    delivery_primary_address: Yup.string()
      .required(t("required"))
      .matches(/^\S.*$/, t("delivery_address_1.invalid"))
      .min(5, t("required_note"))
      .trim(),
    delivery_secondary_address: Yup.string()
      .required(t("required"))
      .matches(/^\S.*$/, t("delivery_address_2.invalid"))
      .min(5, t("required_note"))
      .trim(),
    delivery_city: Yup.string()
      .required(t("required"))
      .matches(/^\S.*$/, t("invalid", { value: "Delivery city" }))
      .min(3, t("min_length_invalid"))
      .trim(),
    delivery_state: Yup.string()
      .required(t("required"))
      .matches(/^\S.*$/, t("invalid", { value: "Delivery state" }))
      .min(2, t("state_length_invalid"))
      .trim(),
    delivery_zipcode: Yup.string()
      .required(t("required"))
      .matches(/^\S.*$/, t("invalid", { value: "Delivery pincode" }))
      .min(4, t("zipcode_length_invalid"))
      .trim(),
    delivery_country: Yup.string()
      .required(t("required"))
      .matches(/^\S.*$/, t("invalid", { value: "Delivery country" }))
      .trim(),
  });

  const togglePasswordVisibility = (value) => {
    if (value == 1)
      setShowPassword((prevState) => ({
        ...prevState,
        old_password: !prevState.old_password,
      }));
    else if (value == 2)
      setShowPassword((prevState) => ({
        ...prevState,
        password: !prevState.password,
      }));
    else
      setShowPassword((prevState) => ({
        ...prevState,
        confirmPassword: !prevState.confirmPassword,
      }));
  };

  const handleSubmit = (values) => {
    // e.preventDefault();
    if (otp) {
      if (otp.length === 6) {
        dispatch(
          tfaStatusUpdateStart({
            verification_code: otp,
            ...values,
          })
        );
      } else {
        getErrorNotificationMessage(
          "The verification code should be 6 digits."
        );
      }
    } else {
      getErrorNotificationMessage("The verification code field is required.");
    }
  };

  const handleCheckboxChange = () => {
    setDeliverySameAsBilling(!deliverySameAsBilling);
    if (!deliverySameAsBilling) {
      // Copy values from billing address to delivery address fields
      const billingAddress = formRef.current.values;
      formRef.current.setValues({
        ...billingAddress,
        delivery_primary_address: billingAddress.billing_primary_address,
        delivery_secondary_address: billingAddress.billing_secondary_address,
        delivery_country: billingAddress.billing_country,
        delivery_state: billingAddress.billing_state,
        delivery_city: billingAddress.billing_city,
        delivery_zipcode: billingAddress.billing_zipcode,
      });
    }
  };

  const handlePasswordChange = (values) => {
    dispatch(changePasswordStart(values));
  };

  const handleAddressChange = (values) => {
    dispatch(updateAddressStart(values));
  };

  const handleServiceTypeChange = (values) => {
    dispatch(updateServiceTypeStart(values));
  };

  useEffect(() => {
    if (
      !skipRender &&
      !changePassword.loading &&
      Object.keys(changePassword.data).length > 0
    ) {
      logout();
    }
    setSkipRender(false);
  }, [changePassword]);

  const handleAddressChanges = () => {
    const billingValues = formRef.current.values;
    const deliveryValues = {
      delivery_primary_address: billingValues.billing_primary_address,
      delivery_secondary_address: billingValues.billing_secondary_address,
      delivery_country: billingValues.billing_country,
      delivery_state: billingValues.billing_state,
      delivery_city: billingValues.billing_city,
      delivery_zipcode: billingValues.billing_zipcode,
    };
    const addressesAreEqual = isEqual(billingValues, deliveryValues);
    setDeliverySameAsBilling(addressesAreEqual);
  };

  useEffect(() => {
    if(!profile.loading && Object.keys(profile.data).length > 0){
      const addressValues = formRef.current?.values;
      const billingValues = addressValues && {
        primary_address: addressValues.billing_primary_address,
        secondary_address: addressValues.billing_secondary_address,
        country: addressValues.billing_country,
        state: addressValues.billing_state,
        city: addressValues.billing_city,
        zipcode: addressValues.billing_zipcode,
      };
      const deliveryValues = addressValues && {
        primary_address: addressValues.delivery_primary_address,
        secondary_address: addressValues.delivery_secondary_address,
        country: addressValues.delivery_country,
        state: addressValues.delivery_state,
        city: addressValues.delivery_city,
        zipcode: addressValues.delivery_zipcode,
      };
      if(isEqual(billingValues, deliveryValues)){
        setDeliverySameAsBilling(true)
      }
    }
  }, [profile]);

  const handleSelect = (key) => {
    setActiveKey(key); 
  };

  useEffect(()=> {
    if(activeKey == "kyb_info") {
      if (!profile.buttonDisable && Object.keys(profile.data).length > 0) {
        profile.data.user_type == 2
          ? dispatch(businessDocumentsStatusStart())
          : dispatch(personalDocumentsStatusStart());
      }
    }
  }, [activeKey])

  useEffect(()=> {
    if(!skipRender && !businessDocumentsStatus.loading && Object.keys(businessDocumentsStatus.data).length > 0 && Object.keys(businessDocumentsStatus.data.kyb_documents).length > 0){
      if(!Object.keys(businessDocumentsStatus.data.kyb_documents.data)?.length > 0){
        getErrorNotificationMessage(businessDocumentsStatus.data.kyb_documents.message || "Unable to fetch KYB documents")
      }
    }
  }, [businessDocumentsStatus])

  return (
    <>
      <div className="setting-full-body-wrapped">
        <div className="efi-setting-feature-wrapped">
          <Tab.Container id="left-tabs" className="" defaultActiveKey={activeKey} onSelect={handleSelect}>
            <Row>
              <Col sm={12}>
                <div className="d-flex align-items-center justify-content-between">
                  <Nav
                    variant="pills"
                    className="flex-row gap-2 efi-setting-tab"
                  >
                    {(profile.data.onboarding >= 3) ? <Nav.Item>
                      <Nav.Link eventKey="onboarding">
                        {" "}
                        <span>{t("onboarding_details")} </span>
                      </Nav.Link>
                    </Nav.Item> : null}
                    {/* {(profile.data.onboarding > 2 && profile.data.user_type == 1) ? <Nav.Item>
                      <Nav.Link eventKey="address">
                        {" "}
                        <span>{t("address")} </span>
                      </Nav.Link>
                    </Nav.Item> : null}
                    {(profile.data.onboarding > 2 && profile.data.user_type == 2) ? (
                      <Nav.Item>
                        <Nav.Link eventKey="business_info">
                          {" "}
                          <span>{t("business_info")} </span>
                        </Nav.Link>
                      </Nav.Item>
                    ) : null} */}
                    <Nav.Item>
                      <Nav.Link eventKey="password">
                        <span>{t("password.name")}</span>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="twoauth">
                        <span>{t("two_factor_authentification")}</span>
                      </Nav.Link>
                    </Nav.Item>
                    {(profile.data.onboarding == 4 && profile.data.user_type == 2) ? (
                      <Nav.Item>
                        <Nav.Link eventKey="kyb_info">
                          {" "}
                          <span>{t("kyb_info")} </span>
                        </Nav.Link>
                      </Nav.Item>
                    ) : null}
                    {(profile.data.onboarding == 4 && profile.data.user_type == 1) ? ((
                      <Nav.Item>
                        <Nav.Link eventKey="kycinfo">
                          <span>{t("kyc_info")}</span>
                        </Nav.Link>
                      </Nav.Item>
                    )) : null}
                    {/* {profile.data.onboarding == 4 &&
                    profile.data.user_type == 1 &&
                    profile.data.is_sub_user == 0 ? (
                      <Nav.Item>
                        <Nav.Link eventKey="service_type">
                          {" "}
                          <span>{t("service_type")} </span>
                        </Nav.Link>
                      </Nav.Item>
                    ) : null} */}
                  </Nav>
                </div>
              </Col>
              <Col sm={12}>
                <Tab.Content className="settings-onboarding-details">
                  {profile.data.onboarding >= 3 ? <Tab.Pane eventKey="onboarding">
                    {profile.data.user_type == 1 ? <IndividualAccountDetails/> : <BusinessAccountDetails/> }
                  </Tab.Pane> : null}
                  {/* <Tab.Pane eventKey="address">
                    <div className="efi-tab-featur-card">
                      <div className="efi-bank-billing-address-frame mb-3">
                        {profile.data.billing_address &&
                          profile.data.delivery_address ? (
                          <div className="efi-bank-billing-address-fields">
                            <Formik
                              initialValues={{
                                billing_primary_address:
                                  profile.data.billing_address
                                    .primary_address || "",
                                billing_secondary_address:
                                  profile.data.billing_address
                                    .secondary_address || "",
                                billing_country:
                                  profile.data.billing_address.country || "",
                                billing_state:
                                  profile.data.billing_address.state || "",
                                billing_city:
                                  profile.data.billing_address.city || "",
                                billing_zipcode:
                                  profile.data.billing_address.zipcode || "",
                                delivery_primary_address:
                                  profile.data.delivery_address
                                    .primary_address || "",
                                delivery_secondary_address:
                                  profile.data.delivery_address
                                    .secondary_address || "",
                                delivery_country:
                                  profile.data.delivery_address.country || "",
                                delivery_state:
                                  profile.data.delivery_address.state || "",
                                delivery_city:
                                  profile.data.delivery_address.city || "",
                                delivery_zipcode:
                                  profile.data.delivery_address.zipcode || "",
                              }}
                              validationSchema={validationSchema}
                              onSubmit={handleAddressChange}
                              innerRef={formRef}
                              onChange={handleAddressChanges}
                            >
                              {({ setFieldValue, values, touched, errors }) => (
                                <FORM
                                  className="efi-bank-default-form efi-onboarding-form-after-login w-100"
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "1em",
                                  }}
                                >
                                  <Row>
                                    <Col md={6}>
                                      <div className="onboarding-auth-header">
                                        <h5>{t("billing_address")}</h5>
                                      </div>
                                      <Form.Group className="mb-3">
                                        <Form.Label className="default-form-label">
                                          {t("billing_address_1.name")}
                                        </Form.Label>
                                        <Field
                                          className="form-control"
                                          type="text"
                                          placeholder={t(
                                            "billing_address_1.placeholder"
                                          )}
                                          aria-label="Name"
                                          aria-describedby="text-addon"
                                          name="billing_primary_address"
                                          onInput={handleAddressChanges}
                                          maxLength={30}
                                        />
                                        <ErrorMessage
                                          name="billing_primary_address"
                                          component="div"
                                          className="errorMsg"
                                        />
                                      </Form.Group>
                                      <Form.Group className="mb-3">
                                        <Form.Label className="default-form-label">
                                          {t("billing_address_2.name")}
                                        </Form.Label>
                                        <Field
                                          type="text"
                                          className="form-control"
                                          placeholder={t(
                                            "billing_address_2.placeholder"
                                          )}
                                          aria-label="Name"
                                          aria-describedby="text-addon"
                                          name="billing_secondary_address"
                                          onInput={handleAddressChanges}
                                          maxLength={30}
                                        />
                                        <ErrorMessage
                                          name="billing_secondary_address"
                                          component="div"
                                          className="errorMsg"
                                        />
                                      </Form.Group>
                                      <Form.Group className="mb-3">
                                        <Form.Label className="default-form-label">
                                          {t("billing_country")}
                                        </Form.Label>
                                        <Select
                                          options={countryOptions}
                                          styles={customStyles}
                                          classNamePrefix="react-select"
                                          onChange={(selectedOption) => {
                                            setFieldValue(
                                              "billing_country",
                                              selectedOption.value
                                            );
                                            handleAddressChanges();
                                          }}
                                          value={countryOptions.find(
                                            (option) =>
                                              option.value ===
                                              values.billing_country
                                          )}
                                        />
                                        <ErrorMessage
                                          name="billing_country"
                                          component="div"
                                          className="errorMsg"
                                        />
                                      </Form.Group>
                                      <Form.Group className="mb-3">
                                        <Form.Label className="default-form-label">
                                          {t("billing_state.name")}
                                        </Form.Label>
                                        <Field
                                          type="text"
                                          className="form-control"
                                          placeholder={t(
                                            "billing_state.placeholder"
                                          )}
                                          aria-label="Name"
                                          aria-describedby="text-addon"
                                          name="billing_state"
                                          onInput={handleAddressChanges}
                                          maxLength={20}
                                        />
                                        <ErrorMessage
                                          name="billing_state"
                                          component="div"
                                          className="errorMsg"
                                        />
                                      </Form.Group>
                                      <Form.Group className="mb-3">
                                        <Form.Label className="default-form-label">
                                          {t("billing_city.name")}
                                        </Form.Label>
                                        <Field
                                          type="text"
                                          className="form-control"
                                          placeholder={t(
                                            "billing_city.placeholder"
                                          )}
                                          aria-label="Name"
                                          aria-describedby="text-addon"
                                          name="billing_city"
                                          onInput={handleAddressChanges}
                                          maxLength={20}
                                        />
                                        <ErrorMessage
                                          name="billing_city"
                                          component="div"
                                          className="errorMsg"
                                        />
                                      </Form.Group>
                                      <Form.Group className="mb-3">
                                        <Form.Label className="default-form-label">
                                          {t("billing_pincode.name")}
                                        </Form.Label>
                                        <Field
                                          type="text"
                                          className="form-control"
                                          placeholder={t(
                                            "billing_pincode.placeholder"
                                          )}
                                          aria-label="Name"
                                          aria-describedby="text-addon"
                                          name="billing_zipcode"
                                          onInput={handleAddressChanges}
                                          maxLength={7}
                                        />
                                        <ErrorMessage
                                          name="billing_zipcode"
                                          component="div"
                                          className="errorMsg"
                                        />
                                      </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                      <div className="onboarding-auth-header d-flex align-items-center justify-content-between">
                                        <h5> {t("delivery_address")}</h5>
                                        <Checkbox
                                          checked={deliverySameAsBilling}
                                          onChange={handleCheckboxChange}
                                          disabled={deliverySameAsBilling}
                                          icon={
                                            <Image
                                              src={
                                                window.location.origin +
                                                "/img/tick-mark.svg"
                                              }
                                              className="tick-mark-checkbox"
                                            />
                                          }
                                        >
                                          {t("same_as_billing_addres")}
                                        </Checkbox>
                                      </div>
                                      <Form.Group className="mb-3">
                                        <Form.Label className="default-form-label">
                                          {t("delivery_address_1.name")}
                                        </Form.Label>
                                        <Field
                                          type="text"
                                          className="form-control"
                                          placeholder={t(
                                            "delivery_address_1.placeholder"
                                          )}
                                          aria-label="Name"
                                          aria-describedby="text-addon"
                                          name="delivery_primary_address"
                                          onInput={handleAddressChanges}
                                          maxLength={30}
                                        />
                                        <ErrorMessage
                                          name="delivery_primary_address"
                                          component="div"
                                          className="errorMsg"
                                        />
                                      </Form.Group>
                                      <Form.Group className="mb-3">
                                        <Form.Label className="default-form-label">
                                          {t("delivery_address_2.name")}
                                        </Form.Label>
                                        <Field
                                          type="text"
                                          className="form-control"
                                          placeholder={t(
                                            "delivery_address_2.placeholder"
                                          )}
                                          aria-label="Name"
                                          aria-describedby="text-addon"
                                          name="delivery_secondary_address"
                                          onInput={handleAddressChanges}
                                          maxLength={30}
                                        />
                                        <ErrorMessage
                                          name="delivery_secondary_address"
                                          component="div"
                                          className="errorMsg"
                                        />
                                      </Form.Group>
                                      <Form.Group className="mb-3">
                                        <Form.Label className="default-form-label">
                                          {t("delivery_country")}
                                        </Form.Label>
                                        <Select
                                          options={countryOptions}
                                          styles={customStyles}
                                          classNamePrefix="react-select"
                                          onChange={(selectedOption) => {
                                            setFieldValue(
                                              "delivery_country",
                                              selectedOption.value
                                            );
                                            handleAddressChanges();
                                          }}
                                          value={countryOptions.find(
                                            (option) =>
                                              option.value ===
                                              values.delivery_country
                                          )}
                                        />
                                        <ErrorMessage
                                          name="delivery_country"
                                          component="div"
                                          className="errorMsg"
                                        />
                                      </Form.Group>
                                      <Form.Group className="mb-3">
                                        <Form.Label className="default-form-label">
                                          {t("delivery_state")}
                                        </Form.Label>
                                        <Field
                                          type="text"
                                          className="form-control"
                                          placeholder={t(
                                            "delivery_state.placeholder"
                                          )}
                                          aria-label="Name"
                                          aria-describedby="text-addon"
                                          name="delivery_state"
                                          onInput={handleAddressChanges}
                                          maxLength={20}
                                        />
                                        <ErrorMessage
                                          name="delivery_state"
                                          component="div"
                                          className="errorMsg"
                                        />
                                      </Form.Group>
                                      <Form.Group className="mb-3">
                                        <Form.Label className="default-form-label">
                                          {t("delivery_city")}
                                        </Form.Label>
                                        <Field
                                          type="text"
                                          className="form-control"
                                          placeholder={t(
                                            "billing_city.placeholder"
                                          )}
                                          aria-label="Name"
                                          aria-describedby="text-addon"
                                          name="delivery_city"
                                          onInput={handleAddressChanges}
                                          maxLength={20}
                                        />
                                        <ErrorMessage
                                          name="delivery_city"
                                          component="div"
                                          className="errorMsg"
                                        />
                                      </Form.Group>
                                      <Form.Group className="mb-3">
                                        <Form.Label className="default-form-label">
                                          {t("delivery_pincode")}
                                        </Form.Label>
                                        <Field
                                          type="text"
                                          className="form-control"
                                          placeholder={t(
                                            "billing_pincode.placeholder"
                                          )}
                                          aria-label="Name"
                                          aria-describedby="text-addon"
                                          name="delivery_zipcode"
                                          onInput={handleAddressChanges}
                                          maxLength={7}
                                        />
                                        <ErrorMessage
                                          name="delivery_zipcode"
                                          component="div"
                                          className="errorMsg"
                                        />
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                  <div className="onboarding-auth-footer">
                                    <div className="space-tow">
                                      <Button
                                        className="efi-primary-btn w-100"
                                        type="submit"
                                        disabled={updateAddress.buttonDisable}
                                      >
                                        {updateAddress.buttonDisable ? (
                                          <ButtonLoader varient="black" />
                                        ) : (
                                          t("confirm")
                                        )}
                                      </Button>
                                    </div>
                                  </div>
                                </FORM>
                              )}
                            </Formik>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </Tab.Pane>
                  {(profile.data.user_type == 2 && profile.data.onboarding > 2) ? (
                    <Tab.Pane eventKey="business_info">
                      <div className="efi-tab-featur-card">
                        <div className="efi-bank-billing-address-frame mb-3">
                          <BusinessInformation />
                        </div>
                      </div>
                    </Tab.Pane>
                  ) : null} */}
                  <Tab.Pane eventKey="password">
                    <div className="efi-tab-featur-card">
                      <Row className="align-items-center justify-content-center">
                        <Col lg="6" xl={5}>
                          <div className="efi-setting-password">
                            <div className="change-password-form-heading mb-3">
                              <h3 className="change-password-heading">
                                {t("change_password")}
                              </h3>
                            </div>
                            <div className="efi-bank-auth-form-box">
                              <Formik
                                initialValues={{
                                  old_password: "",
                                  password: "",
                                  password_confirmation: "",
                                }}
                                validationSchema={changePasswordSchema}
                                onSubmit={handlePasswordChange}
                              >
                                {({
                                  setFieldValue,
                                  values,
                                  touched,
                                  errors,
                                }) => (
                                  <FORM className="efi-bank-default-form">
                                    <div className="mb-3">
                                      <InputGroup>

                                        <Field
                                          className="form-control"
                                          name="old_password"
                                          type={
                                            !showPassword.old_password
                                              ? "text"
                                              : "password"
                                          }
                                          placeholder={t(
                                            "old_password.placeholder"
                                          )}
                                          aria-label="Password"
                                          aria-describedby="Password-addon"
                                          maxLength={16}
                                        />
                                        <InputGroup.Text
                                          className="password-icon"
                                          onClick={() =>
                                            togglePasswordVisibility(1)
                                          }
                                        >
                                          {!showPassword.old_password ? (
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="24"
                                              height="24"
                                              fill="none"
                                              viewBox="0 0 24 24"
                                            >
                                              <path
                                                fill="#c3c7ca"
                                                d="M21.25 9.15C18.94 5.52 15.56 3.43 12 3.43c-1.78 0-3.51.52-5.09 1.49-1.58.98-3 2.41-4.16 4.23-1 1.57-1 4.12 0 5.69 2.31 3.64 5.69 5.72 9.25 5.72 1.78 0 3.51-.52 5.09-1.49 1.58-.98 3-2.41 4.16-4.23 1-1.56 1-4.12 0-5.69zM12 16.04c-2.24 0-4.04-1.81-4.04-4.04S9.76 7.96 12 7.96s4.04 1.81 4.04 4.04-1.8 4.04-4.04 4.04z"
                                              ></path>
                                              <path
                                                fill="#171717"
                                                d="M12 9.14a2.855 2.855 0 000 5.71c1.57 0 2.86-1.28 2.86-2.85S13.57 9.14 12 9.14z"
                                              ></path>
                                            </svg>
                                          ) : (
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="24"
                                              height="24"
                                              fill="none"
                                              viewBox="0 0 24 24"
                                            >
                                              <path
                                                fill="#c3c7ca"
                                                d="M21.25 9.15a14.57 14.57 0 00-1.63-2.11l-3.77 3.77c.12.37.19.77.19 1.19 0 2.24-1.81 4.04-4.04 4.04-.42 0-.82-.07-1.19-.19l-3.46 3.46c1.46.82 3.04 1.25 4.65 1.25 1.78 0 3.51-.52 5.09-1.49 1.58-.98 3-2.41 4.16-4.23 1-1.56 1-4.12 0-5.69z"
                                              ></path>
                                              <path
                                                fill="#171717"
                                                d="M14.02 9.98l-4.04 4.04c-.51-.52-.84-1.24-.84-2.02 0-1.57 1.28-2.86 2.86-2.86.78 0 1.5.33 2.02.84z"
                                              ></path>
                                              <path
                                                fill="#c3c7ca"
                                                d="M18.25 5.75l-3.39 3.39A3.986 3.986 0 0012 7.96c-2.24 0-4.04 1.81-4.04 4.04 0 1.12.45 2.13 1.18 2.86l-3.38 3.39h-.01c-1.11-.9-2.13-2.05-3-3.41-1-1.57-1-4.12 0-5.69C3.91 7.33 5.33 5.9 6.91 4.92c1.58-.96 3.31-1.49 5.09-1.49 2.23 0 4.39.82 6.25 2.32z"
                                              ></path>
                                              <path
                                                fill="#171717"
                                                d="M14.86 12c0 1.57-1.28 2.86-2.86 2.86-.06 0-.11 0-.17-.02l3.01-3.01c.02.06.02.11.02.17zM21.77 2.23c-.3-.3-.79-.3-1.09 0L2.23 20.69c-.3.3-.3.79 0 1.09a.758.758 0 001.08-.01L21.77 3.31c.31-.3.31-.78 0-1.08z"
                                              ></path>
                                            </svg>
                                          )}
                                        </InputGroup.Text>
                                      </InputGroup>
                                      <ErrorMessage
                                        component={"div"}
                                        name="old_password"
                                        className="errorMsg"
                                      />
                                    </div>
                                    <div className="mb-3">
                                      <InputGroup>
                                        <Field
                                          className="form-control"
                                          name="password"
                                          type={
                                            !showPassword.password
                                              ? "text"
                                              : "password"
                                          }
                                          placeholder={t(
                                            "new_password.placeholder"
                                          )}
                                          aria-label="Password"
                                          aria-describedby="Password-addon"
                                          maxLength={16}
                                        />
                                        {/* <InputGroup.Text
                                          className="password-icon"
                                          onClick={() =>
                                            togglePasswordVisibility(2)
                                          }
                                        >
                                          {!showPassword.password ? (
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="24"
                                              height="24"
                                              fill="none"
                                              viewBox="0 0 24 24"
                                            >
                                              <path
                                                fill="#c3c7ca"
                                                d="M21.25 9.15C18.94 5.52 15.56 3.43 12 3.43c-1.78 0-3.51.52-5.09 1.49-1.58.98-3 2.41-4.16 4.23-1 1.57-1 4.12 0 5.69 2.31 3.64 5.69 5.72 9.25 5.72 1.78 0 3.51-.52 5.09-1.49 1.58-.98 3-2.41 4.16-4.23 1-1.56 1-4.12 0-5.69zM12 16.04c-2.24 0-4.04-1.81-4.04-4.04S9.76 7.96 12 7.96s4.04 1.81 4.04 4.04-1.8 4.04-4.04 4.04z"
                                              ></path>
                                              <path
                                                fill="#171717"
                                                d="M12 9.14a2.855 2.855 0 000 5.71c1.57 0 2.86-1.28 2.86-2.85S13.57 9.14 12 9.14z"
                                              ></path>
                                            </svg>
                                          ) : (
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="24"
                                              height="24"
                                              fill="none"
                                              viewBox="0 0 24 24"
                                            >
                                              <path
                                                fill="#c3c7ca"
                                                d="M21.25 9.15a14.57 14.57 0 00-1.63-2.11l-3.77 3.77c.12.37.19.77.19 1.19 0 2.24-1.81 4.04-4.04 4.04-.42 0-.82-.07-1.19-.19l-3.46 3.46c1.46.82 3.04 1.25 4.65 1.25 1.78 0 3.51-.52 5.09-1.49 1.58-.98 3-2.41 4.16-4.23 1-1.56 1-4.12 0-5.69z"
                                              ></path>
                                              <path
                                                fill="#171717"
                                                d="M14.02 9.98l-4.04 4.04c-.51-.52-.84-1.24-.84-2.02 0-1.57 1.28-2.86 2.86-2.86.78 0 1.5.33 2.02.84z"
                                              ></path>
                                              <path
                                                fill="#c3c7ca"
                                                d="M18.25 5.75l-3.39 3.39A3.986 3.986 0 0012 7.96c-2.24 0-4.04 1.81-4.04 4.04 0 1.12.45 2.13 1.18 2.86l-3.38 3.39h-.01c-1.11-.9-2.13-2.05-3-3.41-1-1.57-1-4.12 0-5.69C3.91 7.33 5.33 5.9 6.91 4.92c1.58-.96 3.31-1.49 5.09-1.49 2.23 0 4.39.82 6.25 2.32z"
                                              ></path>
                                              <path
                                                fill="#171717"
                                                d="M14.86 12c0 1.57-1.28 2.86-2.86 2.86-.06 0-.11 0-.17-.02l3.01-3.01c.02.06.02.11.02.17zM21.77 2.23c-.3-.3-.79-.3-1.09 0L2.23 20.69c-.3.3-.3.79 0 1.09a.758.758 0 001.08-.01L21.77 3.31c.31-.3.31-.78 0-1.08z"
                                              ></path>
                                            </svg>
                                          )}
                                        </InputGroup.Text> */}
                                      </InputGroup>
                                      <ErrorMessage
                                        component={"div"}
                                        name="password"
                                        className="errorMsg"
                                      />
                                    </div>
                                    <InputGroup>
                                      <Field
                                        className="form-control"
                                        name="password_confirmation"
                                        type={
                                          !showPassword.confirmPassword
                                            ? "text"
                                            : "password"
                                        }
                                        placeholder={t(
                                          "confirm_password.placeholder"
                                        )}
                                        aria-label="Password"
                                        aria-describedby="Password-addon"
                                        maxLength={16}
                                      />
                                      <InputGroup.Text
                                        className="password-icon"
                                        onClick={() =>
                                          togglePasswordVisibility(3)
                                        }
                                      >
                                        {!showPassword.confirmPassword ? (
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                          >
                                            <path
                                              fill="#c3c7ca"
                                              d="M21.25 9.15C18.94 5.52 15.56 3.43 12 3.43c-1.78 0-3.51.52-5.09 1.49-1.58.98-3 2.41-4.16 4.23-1 1.57-1 4.12 0 5.69 2.31 3.64 5.69 5.72 9.25 5.72 1.78 0 3.51-.52 5.09-1.49 1.58-.98 3-2.41 4.16-4.23 1-1.56 1-4.12 0-5.69zM12 16.04c-2.24 0-4.04-1.81-4.04-4.04S9.76 7.96 12 7.96s4.04 1.81 4.04 4.04-1.8 4.04-4.04 4.04z"
                                            ></path>
                                            <path
                                              fill="#171717"
                                              d="M12 9.14a2.855 2.855 0 000 5.71c1.57 0 2.86-1.28 2.86-2.85S13.57 9.14 12 9.14z"
                                            ></path>
                                          </svg>
                                        ) : (
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                          >
                                            <path
                                              fill="#c3c7ca"
                                              d="M21.25 9.15a14.57 14.57 0 00-1.63-2.11l-3.77 3.77c.12.37.19.77.19 1.19 0 2.24-1.81 4.04-4.04 4.04-.42 0-.82-.07-1.19-.19l-3.46 3.46c1.46.82 3.04 1.25 4.65 1.25 1.78 0 3.51-.52 5.09-1.49 1.58-.98 3-2.41 4.16-4.23 1-1.56 1-4.12 0-5.69z"
                                            ></path>
                                            <path
                                              fill="#171717"
                                              d="M14.02 9.98l-4.04 4.04c-.51-.52-.84-1.24-.84-2.02 0-1.57 1.28-2.86 2.86-2.86.78 0 1.5.33 2.02.84z"
                                            ></path>
                                            <path
                                              fill="#c3c7ca"
                                              d="M18.25 5.75l-3.39 3.39A3.986 3.986 0 0012 7.96c-2.24 0-4.04 1.81-4.04 4.04 0 1.12.45 2.13 1.18 2.86l-3.38 3.39h-.01c-1.11-.9-2.13-2.05-3-3.41-1-1.57-1-4.12 0-5.69C3.91 7.33 5.33 5.9 6.91 4.92c1.58-.96 3.31-1.49 5.09-1.49 2.23 0 4.39.82 6.25 2.32z"
                                            ></path>
                                            <path
                                              fill="#171717"
                                              d="M14.86 12c0 1.57-1.28 2.86-2.86 2.86-.06 0-.11 0-.17-.02l3.01-3.01c.02.06.02.11.02.17zM21.77 2.23c-.3-.3-.79-.3-1.09 0L2.23 20.69c-.3.3-.3.79 0 1.09a.758.758 0 001.08-.01L21.77 3.31c.31-.3.31-.78 0-1.08z"
                                            ></path>
                                          </svg>
                                        )}
                                      </InputGroup.Text>
                                    </InputGroup>
                                    <ErrorMessage
                                      component={"div"}
                                      name="password_confirmation"
                                      className="errorMsg"
                                    />
                                    <div className="efi-auth-signin">
                                      <Button
                                        className="efi-primary-btn w-100"
                                        type="submit"
                                        disabled={changePassword.buttonDisable}
                                      >
                                        {" "}
                                        {changePassword.buttonDisable ? (
                                          <ButtonLoader varient="black" />
                                        ) : (
                                          t("confirm")
                                        )}
                                      </Button>
                                    </div>
                                  </FORM>
                                )}
                              </Formik>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="twoauth">
                    <div className="efi-tab-featur-card">
                      <Row className="align-items-center justify-content-center">
                        <Col lg={7} xl={7}>
                          <div className="efi-setting-password">
                            <div className="efi-bank-step-authentication-head">
                              <h3> {t("two_step_authentication")}</h3>
                            </div>
                            {profile.data.tfa_status == 1 ? (
                              <React.Fragment>
                                <div className="two-step-avater-frame">
                                  <div className="two-step-avater-frame-img">
                                    <Image
                                      src={
                                        window.location.origin +
                                        "/img/feature/two-step-frame.svg"
                                      }
                                      className="step-avater-frame-img"
                                    />
                                  </div>
                                  <div className="head-two-space">
                                    <p>
                                      {t("turn_off_two_factor_authentication")}
                                    </p>
                                    <Switch
                                      // disabled
                                      checked={profile.data.tfa_status}
                                      onChange={() => setPassField(!passField)}
                                    />
                                  </div>
                                </div>
                                {passField ? (
                                  <Formik
                                    initialValues={{
                                      password: "",
                                      email: profile.data.email,
                                    }}
                                    onSubmit={(values) => {
                                      dispatch(tfaStatusUpdateStart(values));
                                      dispatch(tfaEnableFailure({}));
                                      setOtp("");
                                    }}
                                    validationSchema={Yup.object().shape({
                                      password: Yup.string()
                                        .required(t("new_password.required"))
                                        .matches(/^\S*$/, t("new_password.no_spaces"))
                                        .test(
                                          "complexity",
                                          "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character",
                                          (value) => {
                                            if (!value) return false; // Skip validation if value is not provided
                                            const hasUppercase = /[A-Z]/.test(value);
                                            const hasLowercase = /[a-z]/.test(value);
                                            const hasNumber = /[0-9]/.test(value);
                                            const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(value);
                                            return hasUppercase && hasLowercase && hasNumber && hasSpecialChar;
                                          }
                                        )
                                        .min(6, t("new_password.min_length_invalid"))
                                        .max(24, t("new_password.max_length_invalid")),
                                    })}
                                  >
                                    {({ values, setFieldValue }) => (
                                      <FORM className="efi-bank-default-form">
                                        <ul className="list-unstyled">
                                          <li>
                                            <div className="efi-bank-step-authentic-card">
                                              <div className="efi-bank-step-count">
                                                1
                                              </div>
                                              <div className="efi-bank-default-form ">
                                                <p>
                                                  {t(
                                                    "password.enter_your_password"
                                                  )}
                                                </p>
                                                <div className="mb-0">
                                                  <InputGroup>
                                                    <Field
                                                      className="form-control"
                                                      name="password"
                                                      type={
                                                        !tfaShowPassword
                                                          ? "text"
                                                          : "password"
                                                      }
                                                      placeholder={t(
                                                        "password.placeholder"
                                                      )}
                                                      aria-label="Password"
                                                      aria-describedby="Password-addon"
                                                    />
                                                    <InputGroup.Text
                                                      className="password-icon"
                                                      onClick={() =>
                                                        setTfaShowPassword(
                                                          !tfaShowPassword
                                                        )
                                                      }
                                                    >
                                                      {!tfaShowPassword ? (
                                                        <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="24"
                                                        height="24"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                      >
                                                        <path
                                                          fill="#c3c7ca"
                                                          d="M21.25 9.15C18.94 5.52 15.56 3.43 12 3.43c-1.78 0-3.51.52-5.09 1.49-1.58.98-3 2.41-4.16 4.23-1 1.57-1 4.12 0 5.69 2.31 3.64 5.69 5.72 9.25 5.72 1.78 0 3.51-.52 5.09-1.49 1.58-.98 3-2.41 4.16-4.23 1-1.56 1-4.12 0-5.69zM12 16.04c-2.24 0-4.04-1.81-4.04-4.04S9.76 7.96 12 7.96s4.04 1.81 4.04 4.04-1.8 4.04-4.04 4.04z"
                                                        ></path>
                                                        <path
                                                          fill="#171717"
                                                          d="M12 9.14a2.855 2.855 0 000 5.71c1.57 0 2.86-1.28 2.86-2.85S13.57 9.14 12 9.14z"
                                                        ></path>
                                                      </svg>
                                                    ) : (
                                                      <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="24"
                                                        height="24"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                      >
                                                        <path
                                                          fill="#c3c7ca"
                                                          d="M21.25 9.15a14.57 14.57 0 00-1.63-2.11l-3.77 3.77c.12.37.19.77.19 1.19 0 2.24-1.81 4.04-4.04 4.04-.42 0-.82-.07-1.19-.19l-3.46 3.46c1.46.82 3.04 1.25 4.65 1.25 1.78 0 3.51-.52 5.09-1.49 1.58-.98 3-2.41 4.16-4.23 1-1.56 1-4.12 0-5.69z"
                                                        ></path>
                                                        <path
                                                          fill="#171717"
                                                          d="M14.02 9.98l-4.04 4.04c-.51-.52-.84-1.24-.84-2.02 0-1.57 1.28-2.86 2.86-2.86.78 0 1.5.33 2.02.84z"
                                                        ></path>
                                                        <path
                                                          fill="#c3c7ca"
                                                          d="M18.25 5.75l-3.39 3.39A3.986 3.986 0 0012 7.96c-2.24 0-4.04 1.81-4.04 4.04 0 1.12.45 2.13 1.18 2.86l-3.38 3.39h-.01c-1.11-.9-2.13-2.05-3-3.41-1-1.57-1-4.12 0-5.69C3.91 7.33 5.33 5.9 6.91 4.92c1.58-.96 3.31-1.49 5.09-1.49 2.23 0 4.39.82 6.25 2.32z"
                                                        ></path>
                                                        <path
                                                          fill="#171717"
                                                          d="M14.86 12c0 1.57-1.28 2.86-2.86 2.86-.06 0-.11 0-.17-.02l3.01-3.01c.02.06.02.11.02.17zM21.77 2.23c-.3-.3-.79-.3-1.09 0L2.23 20.69c-.3.3-.3.79 0 1.09a.758.758 0 001.08-.01L21.77 3.31c.31-.3.31-.78 0-1.08z"
                                                        ></path>
                                                      </svg>
                                                      )}
                                                    </InputGroup.Text>
                                                  </InputGroup>
                                                  <ErrorMessage
                                                    component={"div"}
                                                    name="password"
                                                    className="errorMsg"
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </li>
                                        </ul>
                                        <div className="efi-bank-default-form mb-5">
                                          <div className="form-modal-footer">
                                            {/* <Button
                                              className="cancel-button"
                                              onClick={props.onHide}
                                            >
                                              cancel
                                            </Button> */}
                                            <Button
                                              type="submit"
                                              className="efi-primary-btn w-100"
                                              disabled={
                                                tfaStatusUpdate.buttonDisable
                                              }
                                            >
                                              {tfaStatusUpdate.buttonDisable ? (
                                                <ButtonLoader />
                                              ) : (
                                                t("confirm")
                                              )}
                                            </Button>
                                          </div>
                                        </div>
                                      </FORM>
                                    )}
                                  </Formik>
                                ) : null}
                              </React.Fragment>
                            ) : (
                              <React.Fragment>
                                <div className="two-step-avater-frame">
                                  <div className="two-step-avater-frame-img ">
                                     <LazyLoadImage
                  effect="blur"
                                      src={
                                        window.location.origin +
                                        "/img/feature/two-step-frame.svg"
                                      }
                                      className="step-avater-frame-img"
                                    />
                                  </div>
                                  <div className="head-two-space">
                                    <p>
                                      {t("turn_on_two_factor_authentication")}
                                    </p>
                                    <Switch
                                      disabled={tfaEnable.buttonDisable}
                                      checked={profile.data.tfa_status}
                                      onChange={() =>
                                        dispatch(tfaEnableStart())
                                      }
                                    />
                                  </div>
                                </div>
                                {tfaEnable.buttonDisable ? (
                                  <Skeleton height={300} />
                                ) : Object.keys(tfaEnable.data).length > 0 ? (
                                  <>
                                    <div className="efi-bank-step-authentication-content pb-0">
                                      <img
                                        src={`data:image/svg+xml;utf8,${encodeURIComponent(
                                          tfaEnable.data.qr_code
                                        )}`}
                                        alt="QR Code"
                                        className="mb-3"
                                        style={{
                                          display: "flex",
                                          margin: "auto",
                                        }}
                                      />
                                      <Formik
                                        initialValues={{
                                          otp: "",
                                          password: "",
                                          email: profile.data.email,
                                        }}
                                        onSubmit={handleSubmit}
                                        validationSchema={tfaSchema}
                                      >
                                        {({ values, setFieldValue }) => (
                                          <FORM className="efi-bank-default-form">
                                            <ul className="list-unstyled">
                                              <li>
                                                <div className="efi-bank-step-authentic-card">
                                                  <div className="efi-bank-step-count">
                                                    1
                                                  </div>
                                                  <div className="efi-bank-step-info">
                                                    <p>
                                                      {t(
                                                        "scan_qr_code_using_auth_app"
                                                      )}
                                                    </p>
                                                    <h5>
                                                      <span>
                                                        {" "}
                                                        {
                                                          tfaEnable.data
                                                            .google2fa_secret
                                                        }{" "}
                                                      </span>{" "}
                                                      <CopyToClipboard text="tfaEnable.data.google2fa_secret">
                                                        <Button
                                                          className="copy-btn"
                                                          onClick={() =>
                                                            getSuccessNotificationMessage(
                                                              "Copied!"
                                                            )
                                                          }
                                                        >
                                                          <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="24"
                                                            height="24"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            stroke="#0194ff"
                                                            stroke-width="2"
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                            class="icon icon-tabler icons-tabler-outline icon-tabler-copy"
                                                          >
                                                            <path
                                                              stroke="none"
                                                              d="M0 0h24v24H0z"
                                                              fill="none"
                                                            />
                                                            <path d="M7 7m0 2.667a2.667 2.667 0 0 1 2.667 -2.667h8.666a2.667 2.667 0 0 1 2.667 2.667v8.666a2.667 2.667 0 0 1 -2.667 2.667h-8.666a2.667 2.667 0 0 1 -2.667 -2.667z" />
                                                            <path d="M4.012 16.737a2.005 2.005 0 0 1 -1.012 -1.737v-10c0 -1.1 .9 -2 2 -2h10c.75 0 1.158 .385 1.5 1" />
                                                          </svg>
                                                        </Button>
                                                      </CopyToClipboard>{" "}
                                                    </h5>
                                                  </div>
                                                </div>
                                              </li>
                                              <li>
                                                <div className="efi-bank-step-authentic-card">
                                                  <div className="efi-bank-step-count">
                                                    2
                                                  </div>
                                                  <div className="efi-bank-step-info">
                                                    <p>
                                                      {t(
                                                        "enter_six_configuration_code"
                                                      )}
                                                    </p>
                                                    <div className="verification-item">
                                                      <OtpInput
                                                        className="otp-box"
                                                        value={otp}
                                                        onChange={setOtp}
                                                        numInputs={6}
                                                        inputType="tel"
                                                        // renderSeparator={<span>-</span>}
                                                        renderInput={(
                                                          props
                                                        ) => (
                                                          <input {...props} />
                                                        )}
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                              </li>
                                              <li>
                                                <div className="efi-bank-step-authentic-card">
                                                  <div className="efi-bank-step-count">
                                                    3
                                                  </div>
                                                  <div className="efi-bank-default-form ">
                                                    <p>
                                                      {t(
                                                        "password.enter_your_password"
                                                      )}
                                                    </p>
                                                    <div
                                                      className="mb-3"
                                                      style={{ width: "100%" }}
                                                    >
                                                      <InputGroup>
                                                        <Field
                                                          className="form-control"
                                                          name="password"
                                                          type={
                                                            !tfaShowPassword
                                                              ? "text"
                                                              : "password"
                                                          }
                                                          placeholder={t(
                                                            "password.placeholder"
                                                          )}
                                                          aria-label="Password"
                                                          aria-describedby="Password-addon"
                                                          maxLength={24}
                                                        />
                                                        <InputGroup.Text
                                                          className="password-icon"
                                                          onClick={() =>
                                                            setTfaShowPassword(
                                                              !tfaShowPassword
                                                            )
                                                          }
                                                        >
                                                          {!tfaShowPassword ? (
                                                            <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="24"
                                                            height="24"
                                                            fill="none"
                                                            viewBox="0 0 24 24"
                                                          >
                                                            <path
                                                              fill="#c3c7ca"
                                                              d="M21.25 9.15C18.94 5.52 15.56 3.43 12 3.43c-1.78 0-3.51.52-5.09 1.49-1.58.98-3 2.41-4.16 4.23-1 1.57-1 4.12 0 5.69 2.31 3.64 5.69 5.72 9.25 5.72 1.78 0 3.51-.52 5.09-1.49 1.58-.98 3-2.41 4.16-4.23 1-1.56 1-4.12 0-5.69zM12 16.04c-2.24 0-4.04-1.81-4.04-4.04S9.76 7.96 12 7.96s4.04 1.81 4.04 4.04-1.8 4.04-4.04 4.04z"
                                                            ></path>
                                                            <path
                                                              fill="#171717"
                                                              d="M12 9.14a2.855 2.855 0 000 5.71c1.57 0 2.86-1.28 2.86-2.85S13.57 9.14 12 9.14z"
                                                            ></path>
                                                          </svg>
                                                        ) : (
                                                          <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="24"
                                                            height="24"
                                                            fill="none"
                                                            viewBox="0 0 24 24"
                                                          >
                                                            <path
                                                              fill="#c3c7ca"
                                                              d="M21.25 9.15a14.57 14.57 0 00-1.63-2.11l-3.77 3.77c.12.37.19.77.19 1.19 0 2.24-1.81 4.04-4.04 4.04-.42 0-.82-.07-1.19-.19l-3.46 3.46c1.46.82 3.04 1.25 4.65 1.25 1.78 0 3.51-.52 5.09-1.49 1.58-.98 3-2.41 4.16-4.23 1-1.56 1-4.12 0-5.69z"
                                                            ></path>
                                                            <path
                                                              fill="#171717"
                                                              d="M14.02 9.98l-4.04 4.04c-.51-.52-.84-1.24-.84-2.02 0-1.57 1.28-2.86 2.86-2.86.78 0 1.5.33 2.02.84z"
                                                            ></path>
                                                            <path
                                                              fill="#c3c7ca"
                                                              d="M18.25 5.75l-3.39 3.39A3.986 3.986 0 0012 7.96c-2.24 0-4.04 1.81-4.04 4.04 0 1.12.45 2.13 1.18 2.86l-3.38 3.39h-.01c-1.11-.9-2.13-2.05-3-3.41-1-1.57-1-4.12 0-5.69C3.91 7.33 5.33 5.9 6.91 4.92c1.58-.96 3.31-1.49 5.09-1.49 2.23 0 4.39.82 6.25 2.32z"
                                                            ></path>
                                                            <path
                                                              fill="#171717"
                                                              d="M14.86 12c0 1.57-1.28 2.86-2.86 2.86-.06 0-.11 0-.17-.02l3.01-3.01c.02.06.02.11.02.17zM21.77 2.23c-.3-.3-.79-.3-1.09 0L2.23 20.69c-.3.3-.3.79 0 1.09a.758.758 0 001.08-.01L21.77 3.31c.31-.3.31-.78 0-1.08z"
                                                            ></path>
                                                          </svg>
                                                          )}
                                                        </InputGroup.Text>
                                                      </InputGroup>
                                                      <ErrorMessage
                                                        component={"div"}
                                                        name="password"
                                                        className="errorMsg"
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                              </li>
                                            </ul>
                                            <div className="efi-bank-default-form">
                                              <div className="form-modal-footer">
                                                <Button
                                                  type="submit"
                                                  className="efi-primary-btn w-100"
                                                  onClick={() =>
                                                    values.password &&
                                                    handleSubmit(values)
                                                  }
                                                  disabled={
                                                    tfaStatusUpdate.buttonDisable
                                                  }
                                                >
                                                  {tfaStatusUpdate.buttonDisable ? (
                                                    <ButtonLoader />
                                                  ) : (
                                                    t("activate")
                                                  )}
                                                </Button>
                                              </div>
                                            </div>
                                          </FORM>
                                        )}
                                      </Formik>
                                    </div>
                                  </>
                                ) : null}
                              </React.Fragment>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Tab.Pane>
                  {(!profile.data.user_type ||
                    profile.data.user_type == 1 )
                    ?
                    <Tab.Pane eventKey="kycinfo">
                      <div className="kyc-card-wrapped">
                        <div className="kyc-card-frame">
                          <div className="kyc-card-frame-info">
                            <p>{t("email")}</p>
                            <h3>{profile.data.email}</h3>
                          </div>
                          {/* <div className="kyc-card-frame-info">
                          <p>Document Type</p>
                          <h3>E_KYC</h3>
                        </div> */}
                          <div className="kyc-card-frame-info">
                            <p>{t("kyc_status")}</p>
                            <h3>
                              {profile.data.user_type == 1
                                ? profile.data.mastercard_kyc_status_formatted
                                : profile.data.iban_kyc_status_formatted}
                            </h3>
                          </div>
                          <div className="kyc-card-frame-info">
                            <p>{t("compliance_status")}</p>
                            <h3>
                              {profile.data.user_type == 1
                                ? profile.data.mastercard_kyc_status_formatted
                                : profile.data.iban_kyc_status_formatted}
                            </h3>
                          </div>
                        </div>
                      </div>
                    </Tab.Pane>
                    :
                    <Tab.Pane eventKey="kyb_info">
                      <div className="kyc-card-wrapped">
                        {businessDocumentsStatus.loading ? (
                          <React.Fragment>
                             <Skeleton className="mb-3" height={100} />
                          {[...Array(3)].map((i, key) => (
                            <Skeleton key={key} height={50} />
                          ))}
                          </React.Fragment>
                          
                        ) : Object.keys(businessDocumentsStatus.data).length >
                          0 ? (
                          <React.Fragment>
                            <div className="kyc-card-wrapped mb-3">
                              <div className="kyc-card-frame">
                                <div className="kyc-card-frame-info">
                                  <p>{t("email")}</p>
                                  <h3>{profile.data.email}</h3>
                                </div>
                                <div className="kyc-card-frame-info">
                                  <p>{t("kyc_status")}</p>
                                  <h3>
                                    {profile.data.user_type == 1
                                      ? profile.data
                                        .mastercard_kyc_status_formatted
                                      : profile.data.iban_kyc_status_formatted}
                                  </h3>
                                </div>
                                <div className="kyc-card-frame-info">
                                  <p>{t("compliance_status")}</p>
                                  <h3>
                                    {profile.data.user_type == 1
                                      ? profile.data
                                        .mastercard_kyc_status_formatted
                                      : profile.data.iban_kyc_status_formatted}
                                  </h3>
                                </div>
                              </div>
                            </div>
                            <Table className="common-table" responsive>
                              <thead className="common-table-header">
                                <tr>
                                  <th>Document</th>
                                  <th>Status</th>
                                </tr>
                              </thead>
                              <tbody className="common-table-body">
                                {businessDocumentsStatus.data.kyb_documents.data?.KYBDocumentsUplodedDetails?.length > 0 ? (
                                  businessDocumentsStatus.data.kyb_documents.data.KYBDocumentsUplodedDetails.map(
                                    (item, i) => (
                                      <tr key={i}>
                                        <td
                                          style={{
                                            textTransform: "capitalize",
                                          }}
                                        >
                                          {item.document_type
                                            .split("_")
                                            .join(" ")}
                                        </td>
                                        <td className="delete-contact-btn-sec">
                                          {/* <a href={item.link} target="blank">
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="18"
                                              height="12"
                                              fill="none"
                                              viewBox="0 0 18 12"
                                            >
                                              <path
                                                fill="#171717"
                                                fillRule="evenodd"
                                                d="M5.875 6a3.125 3.125 0 116.25 0 3.125 3.125 0 01-6.25 0zM9 4.125a1.875 1.875 0 100 3.75 1.875 1.875 0 000-3.75z"
                                                clipRule="evenodd"
                                              ></path>
                                              <path
                                                fill="#171717"
                                                fillRule="evenodd"
                                                d="M2.603 4.872c-.35.503-.478.897-.478 1.128 0 .231.129.625.478 1.128.337.487.84 1.014 1.474 1.501C5.35 9.606 7.095 10.375 9 10.375c1.905 0 3.65-.77 4.923-1.746.635-.487 1.136-1.014 1.474-1.501.35-.503.478-.897.478-1.128 0-.231-.129-.625-.478-1.128-.338-.487-.84-1.014-1.474-1.501C12.65 2.394 10.905 1.625 9 1.625c-1.905 0-3.65.77-4.923 1.746-.635.487-1.137 1.014-1.474 1.501zm.713-2.493C4.758 1.273 6.763.375 9 .375s4.242.898 5.684 2.004c.722.555 1.319 1.173 1.74 1.78.41.59.701 1.239.701 1.841 0 .602-.291 1.25-.7 1.84-.422.608-1.019 1.226-1.741 1.78-1.442 1.107-3.447 2.005-5.684 2.005s-4.242-.898-5.684-2.004c-.722-.555-1.319-1.173-1.74-1.78C1.166 7.25.875 6.602.875 6c0-.602.291-1.25.7-1.84.422-.608 1.019-1.226 1.741-1.78z"
                                                clipRule="evenodd"
                                              ></path>
                                            </svg>
                                          </a> */}
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            fill="none"
                                            viewBox="0 0 21 21"
                                          >
                                            <path
                                              fill="#3FBF5B"
                                              d="M8.456 2.383a2.694 2.694 0 014.088 0 2.694 2.694 0 002.25.932 2.694 2.694 0 012.892 2.89c-.066.856.28 1.692.931 2.25a2.694 2.694 0 010 4.09 2.694 2.694 0 00-.931 2.25 2.694 2.694 0 01-2.892 2.89 2.694 2.694 0 00-2.25.932 2.694 2.694 0 01-4.088 0 2.694 2.694 0 00-2.25-.931 2.694 2.694 0 01-2.891-2.892 2.694 2.694 0 00-.932-2.25 2.694 2.694 0 010-4.088 2.694 2.694 0 00.932-2.25 2.694 2.694 0 012.89-2.891 2.694 2.694 0 002.25-.932z"
                                            ></path>
                                            <path
                                              stroke="#fff"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                              strokeWidth="2"
                                              d="M8 11.4L9.429 13 13 9"
                                            ></path>
                                          </svg>
                                        </td>
                                      </tr>
                                    )
                                  )
                                ) : (
                                  <tr>
                                    <td colSpan="8" className="text-center">
                                      <NoDataFound />
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </Table>
                          </React.Fragment>
                        ) : (
                          <NoDataFound />
                        )}
                      </div>
                    </Tab.Pane>
                  }
                  {/* {profile.data.onboarding == 4 &&
                  profile.data.user_type == 1 &&
                  profile.data.is_sub_user == 0 ? (
                    <Tab.Pane eventKey="service_type">
                      <div className="efi-tab-featur-card">
                        <Row className="align-items-center justify-content-center">
                          <Col lg="6" xl={5}>
                            <div className="efi-setting-password">
                              <div className="change-password-form-heading mb-3">
                                <h3 className="change-password-heading">
                                  {t("update_service_type")}
                                </h3>
                              </div>
                              <div className="efi-bank-auth-form-box">
                                <Formik
                                  initialValues={{
                                    mastercard_service:
                                      profile.data.mastercard_service,
                                    iban_service: profile.data.iban_service,
                                    service_type: "",
                                  }}
                                  onSubmit={handleServiceTypeChange}
                                >
                                  {({
                                    setFieldValue,
                                    values,
                                    touched,
                                    errors,
                                  }) => (
                                    <FORM className="efi-bank-default-form">
                                      <div className="mb-3">
                                        <div className="head-two-space">
                                          <p>{t("card_service")}</p>
                                          <Switch
                                            disabled={
                                              profile.data.mastercard_service
                                            }
                                            checked={values.mastercard_service}
                                            onChange={() => {
                                              setFieldValue(
                                                "mastercard_service",
                                                !values.mastercard_service
                                              );
                                              setFieldValue(
                                                "service_type",
                                                values.service_type == 1
                                                  ? ""
                                                  : 1
                                              );
                                            }}
                                          />
                                        </div>
                                      </div>
                                      <div className="mb-3">
                                        <div className="head-two-space">
                                          <p>{t("iban_service")}</p>
                                          <Switch
                                            disabled={profile.data.iban_service}
                                            checked={values.iban_service}
                                            onChange={() => {
                                              setFieldValue(
                                                "iban_service",
                                                !values.iban_service
                                              );
                                              setFieldValue(
                                                "service_type",
                                                values.service_type == 2
                                                  ? ""
                                                  : 2
                                              );
                                            }}
                                          />
                                        </div>
                                      </div>
                                      <div className="efi-auth-signin">
                                        <Button
                                          className="efi-primary-btn w-100"
                                          type="submit"
                                          disabled={
                                            updateServiceType.buttonDisable ||
                                            (values.iban_service ==
                                              profile.data.iban_service &&
                                              values.mastercard_service ==
                                                profile.data.mastercard_service)
                                          }
                                        >
                                          {" "}
                                          {updateServiceType.buttonDisable ? (
                                            <ButtonLoader varient="black" />
                                          ) : (
                                            t("update")
                                          )}
                                        </Button>
                                      </div>
                                    </FORM>
                                  )}
                                </Formik>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Tab.Pane>
                  ) : null} */}
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </div>
      </div>
      {authModalShow ? <TwoSteoAuthModal
        show={authmodalShow}
        onHide={() => authModalShow(false)}
      /> : null}
      {passwordChangeModal ? <ChangePasswordModal
        show={passwordChangeModal}
        onHide={() => setPasswordChangeModal(false)}
      /> : null}
      {deleteaccountModal ? <DeleteAccount
        show={deleteaccountModal}
        onHide={() => setDeleteaccountModal(false)}
      /> : null}
      {logoutdevices ? <LogOutAllDevicesModal
        show={logoutdevices}
        onHide={() => setLogoutdevices(false)}
      /> : null}
    </>
  );
};

export default SettingIndex;
