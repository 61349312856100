import React from "react";
import { Modal, Button, Form, Row, Col, Image } from "react-bootstrap";

const StatementModal = (props) => {
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <h3 className="change-password-heading">Statement Details</h3>
      </Modal.Header>
      <Modal.Body className="pt-0">
        <div className="quote-info-card">
          <div className="quote-card">
            <h5>Transaction ID</h5>
            <p>
              ID:
              <span>{props.show.transaction_id}</span>
            </p>
          </div>
          {/* <div className="card-part"></div> */}
          <div className="quote-card">
            <h5>Transaction Type</h5>
            <p>
              Type:
              <span>{props.show.type}</span>
            </p>
          </div>
          {/* <div className="card-part"></div> */}
          <div className="quote-card">
            <h5>Status</h5>
            <p>
              Status:
              <span>{props.show.status}</span>
            </p>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default StatementModal;
