import React from "react";
import { Modal, Button, Form } from "react-bootstrap";
import './modal.css';

const LogOutAllDevicesModal = (props) => {
    return (
        <>
            <Modal
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body>
                    <div className="default-form-heading mb-3">
                        <Form.Label>Logout all Devices</Form.Label>
                    </div>
                    <div className="efi-bank-default-form">
                        <div className="modal-wrapped">
                            <p className="default-form-label">If you notice any suspicious activity, then Logout of your EFIcyent profile from all devices and browsers.</p>
                        </div>
                        <div className="form-modal-footer">
                            <Button className="cancel-button" onClick={props.onHide}>
                                cancel
                            </Button>
                            <Button className="submit-button">
                            Logout all Devices
                            </Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
};

export default LogOutAllDevicesModal;